import React from "react";
import { Route, Switch } from "react-router-dom";
import { SpecialHispanic } from "./pages/special-hispanic";

export const Pautas = ()=>{
    return (
        <>
        <Switch>
            <Route exact path={'/pautas/special-hispanic-heritage-offer-2024'}>
                <SpecialHispanic/>
            </Route>
        </Switch>
        </>
    )
}
import React, { useMemo, useEffect, useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import {
  ASSETS_URL,
  store,
  librosMaxDescargados,
  getAllFromIndexDB,
  diasVencimientoDescarga,
  setDB,
  deleteDB,
  GENERAL_URL_API,
  STREAMER_URL_API
} from "../components/Store";
import MicroModal from "micromodal";
import { Icon } from '@iconify/react';
import { useBookData } from "./useBookData";
import useIsMobile from "../js/utilities/useIsMobile";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { ModoPWAContext } from "../contexts/ModoPwaContext.js";
import { VisorAPI } from "../services/api/visor.js";
import { EpubAPI } from "../services/api/epub.js";
import FsLightbox from "fslightbox-react";
import { METADATA_TRANSLATE_FICHA } from "./informacionFicha/translateMetadata";
import normalizeString from "../js/utilities/normalizeString";
import _ from 'lodash';
import { FavoritesStorage } from "../util/favorites-storage";
import { useFavoritesBooks } from "../hooks/useFavoritesBooks";

//componentes
import { Cover } from "./Cover";
import ModalAlertas from "../components/ModalAlertas";
import { ModalCompartir } from "../components/modals/modal-compartir";
import InfoBasica from "./informacionFicha/InfoBasica";
import InfoDetalle from "./informacionFicha/InfoDetalle";
import SkeletonFichaLibro from "../skeletons/SkeletonsFichaLibro/SkeletonFichaLibro";
import { SkeletonRecomendados } from "../skeletons/SkeletonsFichaLibro/SkeletonRecomendados";
import AlertaLimite from "./AlertaLimite";
import { InvitacionLectura } from "./invitacion-lectura.js";
import { SkeletonTagsRecomendados } from "../skeletons/SkeletonsFichaLibro/SkeletonTagsRecomendados";
import { NotAvailableBook } from "./not-available-book";

import { SolicitudDemo } from "../components/solicitud-demo/index.js";

import { Helmet } from "react-helmet";
import { useBookSelection } from "./hooks/use-book-selection.js";

/**
 * Componente que no recibe props. Este renderiza la pantalla de ficha del libro con su información correspondiente. Entre sus funciones principales estan obtener la información del libro, definir si el libro puede ser descargado y descargar libro. Este hace uso de los componentes Cover, ModalAlertas, ModalCompartir, InfoBasica, InfoDetalle, InvitacionLectura y AlertaLimite. 
 * @returns Ficha
 */
const Ficha = () => {
  const params = useParams();
  const { isReady, textosSubSecciones: textoFicha } = useObtenerSubsecciones('ficha_libro');

  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";
  const isSmallCollection = globalState?.state?.cantidadLibros < 40;
  const idioma = state.idioma;
  const pwaState = useContext(ModoPWAContext);
  const isMobile = useIsMobile();
  //Libro favorito
  const user = globalState.state.username;
  const favorites = useFavoritesBooks({ userName: user })

  //Datos ficha
  let book = useBookData(params?.idLibro);
  const isFree = book?.metadata?.gratis === 1 || book?.metadata?.gratis === true;
  const metadata = useMemo(() => book.metadata, [book.metadata])
  const bookAvailable = (metadata?.territorio && typeof metadata?.territorio === "string" && metadata?.territorio?.trim()?.length > 0);
  const [idLibro, setIdLibro] = useState("");
  const [urlLibro, setUrlLibro] = useState("");
  const [backs, setBacks] = useState([]);

  //Datos descarga
  const [alertaLimite, setAlertaLimite] = useState(false);
  const [descargado, setDescargado] = useState(false);
  const [numLibrosDescargados, setNumLibrosDescargados] = useState(0);

  //Acciones ficha
  const [activeTab, setActiveTab] = useState("informacion-basica");
  const [favorite, setFavorite] = useState(false);
  const [toggler, setToggler] = useState(false);

  const bookSelectionManager = useBookSelection({bookId:params?.idLibro})
  const isBookSelectionMode = bookSelectionManager.isBookSelectionMode;


  useEffect(() => {
    setIdLibro(params.idLibro);
    setDescargado(false);
    getAllFromIndexDB("tipo", "metadatos").then(function (results) {
      let contador = 0;
      results.forEach((result) => {
        let fechaVencimientoDescargaTemp = new Date();
        let fechaHoy = new Date();
        fechaVencimientoDescargaTemp.setDate(
          result.fecha.getDate() + diasVencimientoDescarga + 1
        );
        if (fechaVencimientoDescargaTemp > fechaHoy) {
          if (result.idLibro === params.idLibro) {
            setDescargado(true);
          }
          contador++;
        } else {
          eliminarLibro(result.idLibro);
        }
      });

      setNumLibrosDescargados(contador);
    });
  }, [params.idLibro]);

  //Url libro: actualiza el estado urlLibro
  useEffect(() => {
    let href;
    if (isLogged && state.cantidadLibros === 0) {
      href = "/mi-panel/mi-coleccion";
    }
    else if (isLogged && state.cantidadLibros > 0) {
      href = `/visor/${params?.idLibro}`;
    }
    else if (!isLogged && book?.metadata?.gratis === 1) {
      href = `/visor/${params?.idLibro}`;
    }
    else {
      href = `/login?bookT=/visor/${params?.idLibro}/`;
    }
    setUrlLibro(href);
  }, [book, isLogged, params.idLibro, state.cantidadLibros])

  /**define el orden de las imagenes de fondo, de los temas, de manera aleatoria */
  useEffect(() => {
    let fondos = [];
    let cantidadNumeros = 5;
    while (fondos.length < cantidadNumeros) {
      let numeroAleatorio = Math.ceil(Math.random() * cantidadNumeros);
      let existe = false;
      for (let i = 0; i < fondos.length; i++) {
        if (fondos[i] == numeroAleatorio) {
          existe = true;
          break;
        }
      }
      if (!existe) {
        fondos[fondos.length] = numeroAleatorio;
      }

    }
    setBacks(fondos)
  }, [])

  /**
   * Define si el libro pertenece a los favoritos.
   */
  useEffect(() => {
    let isFavorite = favorites.includes(params.idLibro);
    setFavorite(isFavorite);
  }, [params.idLibro, favorites])

  /**
   * Esta función se encarga de dibujar las figuras con los temas recomendados 
   */
  const temasDeInteres = () => {
    let temas = [];
    if (isMobile) {
      Object.keys(book.recommendedTags).map((tag, index) => {
        if (index < 2) {
          temas.push(
            <Link
              key={`tema-${index}`}
              to={`/busqueda?sch=${book.recommendedTags[tag]}`}
              className="ficha-tema mb-3"
              style={{ backgroundImage: `url('/img/ficha/back${backs[index]}.png')` }}
            >
              <span>{book.recommendedTags[tag]}</span>
            </Link>
          )
        }
      })
    } else {
      Object.keys(book.recommendedTags).map((tag, index) => {
        temas.push(
          <Link
            key={`tema-${index}`}
            to={`/busqueda?sch=${book.recommendedTags[tag]}`}
            className="ficha-tema mb-3"
            style={{ backgroundImage: `url('/img/ficha/back${backs[index]}.png')` }}
          >
            <span>{book.recommendedTags[tag]}</span>
          </Link>
        )
      })
    }
    return temas;
  }

  /**
     * Esta función retorna el link del blog en caso de que el libro este relacionado. 
   */
  const linkBlog = () => {
    if (metadata?.linkBlog !== undefined && metadata?.linkBlog !== '') {
      return (
        <Link
          to={metadata.linkBlog}
          className="ficha-link-blog focusable-secondary d-inline-block mb-4"
        >
          {textoFicha?.mas_detalles?.entrada || 'Entrada relacionada del blog'}{" "}
          <i className="fas fa-external-link-alt"></i>
        </Link>
      )
    }
  }

  /**
   * Función que define si el libro puede ser descargado
   * @param {Object} book 
   */
  const downloadManager = async (book) => {
    if (numLibrosDescargados < librosMaxDescargados) {
      dispatch({
        type: "CHANGE_VALUE",
        property: "errorDescargandoLibro",
        value: false,
      });
      dispatch({ type: "CHANGE_VALUE", property: "descargando", value: true });
      dispatch({
        type: "CHANGE_VALUE",
        property: "idLibroDescargando",
        value: book?.idLibro,
      });
      dispatch({
        type: "CHANGE_VALUE",
        property: "tituloDescargando",
        value: metadata.titulo,
      });
      dispatch({
        type: "CHANGE_VALUE",
        property: "progresoDescarga",
        value: "0%",
      });
      if (typeof book !== "object" || book === ({})) { return }
      const visorBook = await getBookFromVisorAPI(idLibro);
      downloadBook(visorBook);
    }

    if (numLibrosDescargados >= librosMaxDescargados) {
      setAlertaLimite(true);
      return;
    }

  }

  /**
   * Función que retorna los datos del libro con todos sus archivos
   * @param {string} bookdId 
   * @returns {Promise<Libro>} 
   */
  const getBookFromVisorAPI = async (bookdId) => {
    return await VisorAPI.getBookData({ abortSignal: null, data: { bookId: bookdId } })
  }

  /**
   * Función que elimina el libro
   */
  const eliminarLibro = (idLibroInt) => {
    let arrayTemp = [];
    getAllFromIndexDB("idLibro", idLibroInt).then(function (results) {
      results.forEach((result) => {
        if (arrayTemp.indexOf(result.url) === -1) {
          arrayTemp.push(result.url);
        }
      });
      arrayTemp.forEach((urlABorrar) => {
        deleteDB(urlABorrar);
      });
    });
  };

  /**
   * Función que descarga el libro y guarda la infomación en el globalState
   * @param {Object} book 
   */
  const downloadBook = async (book) => {
    if (book?.formato === "epub") {
      const origin = window?.location?.origin;
      /**
       * Se comenta los archivos que se deben descargar para el lector de epubs de DITA.
       */
      // await getEpubAssets()
      //   .then(data => {
      // book.Archivos = [
      //   book.Archivos[0],
      //   `https://rc.makemake.reallabs.com.co${book.Archivos[0]}`,
      //   ...data.assets.book,
      //   ...data.assets.styles.map(style => style?.url || ''),
      //   data.getManifest,
      //   data.manifestJSON
      // ]

      if (origin === 'https://makemake.com.co') {
        book.Archivos = [
          ...book.Archivos,
          `/makemake${book.Archivos[0]}`
        ]
      }
      else {
        book.Archivos = [
          ...book.Archivos,
          `https://rc.makemake.reallabs.com.co${book.Archivos[0]}`
        ]
      }
      // })
      // .catch(error => console.log(error))
    }
    else {
      book.Archivos = book.Archivos.map(file => {
        return '/makemake' + file
      })

    }
    if (book?.formato === 'jpg') {
      const jsonAccesibilityPath = `${window.location.hostname.includes('localhost') || window.location.hostname.includes('rc.app') || window.location.hostname.includes('nf.app') ? 'https://rc.makemake.reallabs.com.co' : '/makemake'}${book?.Accesibilidad || ''}`
      book.Archivos = [
        ...book?.Archivos,
        jsonAccesibilityPath
      ]
    }

    /* console.log('book to download', book) */

    let contador = 0;
    let totalArchivos = Array.isArray(book.Archivos) ? book.Archivos.length : 0;

    // Guardar los assets del libro

    Promise.all(book?.Archivos.map((url, i) => {
      return fetch(url, { signal: state.abortSignal, mode: "cors" })
        .then(resp => {
          return resp.clone().blob().then(async result => {
            let data = {
              url: url,
              idLibro: book?.idLibro,
              tipo: "contenido",
              fecha: new Date(),
              blob: result,
            };
            if (url.endsWith("index.html")) {
              let strippedUrl = url.substring(
                0,
                url.length - 10
              );
              await setDB({ ...data, url: strippedUrl });
            }
            if (url.endsWith("index.htm")) {
              let strippedUrl = url.substring(
                0,
                url.length - 9
              );
              await setDB({ ...data, url: strippedUrl });
            }
            if (url.endsWith("index.xhtml")) {
              let strippedUrl = url.substring(
                0,
                url.length - 9
              );
              await setDB({ ...data, url: strippedUrl });
            }

            return setDB(data).then(() => {
              contador++;
              let porcentaje = Math.round(
                (100 * contador) / totalArchivos
              );
              dispatch({
                type: "CHANGE_VALUE",
                property: "progresoDescarga",
                value: porcentaje + "%",
              });
            });
          });
        })
    }
    )).then(results => {
      // guardar metadatos y portada  del libro para visualizar en la sección de descargados.
      let metadatosEntry = {
        url: `${GENERAL_URL_API}/assets/visor?idLibro=${book?.idLibro}`,
        idLibro: book?.idLibro,
        tipo: "metadatos",
        fecha: new Date(),
        username: state.username,
        metadatos: book,
      };
      setDB(metadatosEntry).then(async r => {
        let portadaUrl = `/makemake${book.portada}`;
        await fetch(portadaUrl, { signal: state.abortSignal })
          .then(function (portadaResponse) {
            let respClone = portadaResponse.clone();
            respClone.blob().then((blob) => {
              let data = {
                url: `/makemake${book.portada}`,
                idLibro: book?.idLibro,
                tipo: "portada",
                fecha: new Date(),
                blob: blob,
              };
              setDB(data).then(r => {
                /* console.log('Book downloaded successfully!'); */
                setDescargado(true);
                dispatch({
                  type: "CHANGE_VALUE",
                  property: "errorDescargandoLibro",
                  value: false,
                });
              })
            });
          })
          .catch(error => console.log('Error downloading book cover: ', error))
      })
        .catch(error => console.log(error))
    })
      .catch(error => {
        dispatch({
          type: "CHANGE_VALUE",
          property: "errorDescargandoLibro",
          value: true,
        });
        dispatch({
          type: "CHANGE_VALUE",
          property: "progresoDescarga",
          value: "0%",
        });

        console.log('Book downloading error: ', error)
      })
  };

  /**
  * Función que guarda la información de los libros tipo epub en el globalState
  */
  const getEpubAssets = async () => {
    let epubUrl = null;
    let epubManifest = null;
    let epubAsssets = null;
    await VisorAPI.getAssets({ abortSignal: null, data: { bookId: idLibro } })
      .then(assets => {
        epubUrl = assets[0];
      })
      .catch(error => console.log(error))

    await EpubAPI.getBookManifest({ abortSignal: null, data: { epubUrl: epubUrl } })
      .then(epubMani => {

        epubManifest = epubMani;

      })
      .catch(error => { console.log(error) })
    await EpubAPI.getAssets({ abortSignal: null, data: { epubManifest: epubManifest?.manifest } })
      .then(assets => {
        epubAsssets = assets;
      })
      .catch(error => { console.log(error) })

    return {
      assets: epubAsssets,
      getManifest: `${STREAMER_URL_API}/getManifest?url=${epubUrl}`,
      manifestJSON: `${STREAMER_URL_API}${epubManifest?.manifest}`
    };
  }

  //Visor screens ficha
  const visorScreens = () => (
    <>
      <FsLightbox
        toggler={toggler}
        disableLocalStorage={true}
        // loadOnlyCurrentSource={true}
        type="image"
        sources={[
          `${ASSETS_URL}${metadata.screen1}`,
          `${ASSETS_URL}${metadata.screen2}`,
          `${ASSETS_URL}${metadata.screen3}`,
        ]}
      />
    </>
  );

  /**
   * Esta función toma el texto de materia y asigna el texto correpondiente al idioma activo
   * @param {string} data equivale al texto de materia
   * @returns textoMateriaTag
   */
  const textoMateriaTag = (data) => {
    const lista = METADATA_TRANSLATE_FICHA['materia'];
    const normalizeValue = normalizeString(data);
    let path = '';
    lista.map((item) => {
      if (normalizeValue === item.nombre) {
        path = item.pathTranslate;
      }
    })

    return <p className="card-materia mb-2">{_.get(textoFicha, path) || 'N/A'} </p>
  }

  /**
   * Agrega o elimina el libro los favoritos en el localstorage
   */
  const agregarRemoverAFavoritos = () => {
    if (favorite === false) {
      FavoritesStorage.saveFavorites(user, idLibro);
    } else {
      FavoritesStorage.removeFavorites(user, idLibro);
    }
  }

  //render FICHA
  if (book?.loading?.bookData) {
    return (
      <SkeletonFichaLibro
        isLogged={isLogged}
        recomendados={book.recommendedBooks}
      />
    )
  } else {
    return (
      <>

        <Helmet>
          <title>{`MakeMake - ${metadata.titulo}`}</title>
          <meta name="description" content={ idioma === 'es' ?  metadata?.descripcion || 'N/A' : metadata?.descripcionEn || metadata?.descripcion || 'N/A' } />
          <link  rel="canonical" href={`https://makemake.com.co/fichas/${params?.idLibro}/${params?.nombreLibro}`} />
        </Helmet>

        {alertaLimite && (
          <AlertaLimite setAlertaLimite={setAlertaLimite} />
        )}
        {isReady &&
          <>
            <div className="main-container">
              <InvitacionLectura />
              <div className={`container contenedor-ficha  ${isLogged ? 'p-4 p-lg-5' : 'px-4  pt-4 px-lg-5 pt-lg-5'}`}>
                {
                  isLogged && !bookSelectionManager.loading && isBookSelectionMode && bookAvailable && !isFree?
                  <div className="book-selection-bar w-100 p-2 my-2 d-block px-3">
                    <div className="w-100 d-flex justify-content-between align-items-center">
                      <div className="">
                        <p className="font-weight-bold onbackground-primary--color">{textoFicha?.seleccion_libros?.libros_agregados || 'Libros añadidos'} <span className="secondary-inverse--color">{`(${bookSelectionManager.currentSelectedBooksLength}/${bookSelectionManager.maxBooks})`}</span></p>
                      </div>
                      <div className="d-flex align-items-center">
                        {
                          bookSelectionManager.isCurrentAdded?
                          <>
                          <p className="font-weight-bold onbackground-primary--color mr-2 d-flex justify-content-center align-items-center"><Icon icon="ic:outline-check" className="" width={24} />{textoFicha?.seleccion_libros?.agregado || 'Añadido'}</p>
                          <button className="remove-button focusable-primary" onClick={()=>bookSelectionManager.remove()}>{textoFicha?.seleccion_libros?.remover || 'Remover'}</button>
                          </>
                          :
                        <button className="add-button focusable-primary mr-2" onClick={()=>bookSelectionManager.add()} disabled={bookSelectionManager.currentSelectedBooksLength > bookSelectionManager.maxBooks - 1}>{textoFicha?.seleccion_libros?.agregar || 'Agregar'}</button>
                        }

                      </div>
                    </div>
                </div>
                  :
                  null
                }
                {
                  !isLogged && !bookAvailable &&
                  <NotAvailableBook />
                }
                <h1 className="tituloFicha mb-4 mb-lg-0">{metadata.titulo}</h1>

                <nav className="nav-ficha mt-3 mb-5 d-flex justify-content-end">
                  <button
                    onClick={() => setActiveTab("informacion-basica")}
                    aria-current={activeTab === "informacion-basica" ? 'page' : false}
                    className={`px-3 py-2 nav-button-ficha focusable-red ${activeTab === "informacion-basica" && 'nav-button-ficha-active'}`}
                  >
                    {textoFicha?.info_basica?.titulo_pestana || 'Información básica'}
                  </button>
                  {isLogged &&
                    <button
                      aria-current={activeTab === "mas-detalles" ? 'page' : false}
                      onClick={() => setActiveTab("mas-detalles")}
                      className={`px-3 py-2 ml-3 nav-button-ficha focusable-red ${activeTab === "mas-detalles" && 'nav-button-ficha-active'}`}
                    >
                      {textoFicha?.mas_detalles?.titulo_pestana || 'Más detalles'}
                    </button>
                  }
                </nav>

                <div className={`row  ${isLogged && 'mb-5'}`}>
                  <aside className="col-lg-4 mb-4 mb-lg-0">
                    <Cover metadata={{ ...metadata, portada: `${ASSETS_URL}${metadata?.portada}` }} />

                    {/* favorito */}
                    {
                      isLogged &&
                      <button
                        className="d-block mx-auto mt-4 btn-favorito focusable-secondary"
                        onClick={() => agregarRemoverAFavoritos()}
                      >
                        {favorite ?
                          <Icon icon="material-symbols:favorite" className="mx-1 red-inverse--color" width={30} />
                          :
                          <Icon icon="material-symbols:favorite-outline" className="mx-1" width={30} />
                        }
                        {textoFicha?.botones?.favorito || 'Marcar como favorito'}
                      </button>
                    }


                    {/* ver unas paginas */}
                    {
                      (isLogged || (!isLogged && bookAvailable)) &&

                      <>
                        <button
                          className="btn btn-secondary focusable-red px-2 mt-4 mx-auto d-block col-sm-10 col-lg-9"
                          onClick={() => setToggler(!toggler)}
                        >
                          {textoFicha?.botones?.ver || 'Ver unas páginas'}
                        </button>
                        {visorScreens()}
                      </>
                    }
                    {/* leer */}
                    {
                      (isLogged || (!isLogged && bookAvailable)) &&
                      <Link
                        to={urlLibro}
                        className="btn btn-primary focusable-red px-5 mt-4 mx-auto d-block col-sm-10 col-lg-9"
                        onClick={(e) => {
                          if (metadata?.limiteDeLecturasAlcanzado) {
                            e.preventDefault();
                            MicroModal.show("modal-alerts", {
                              awaitCloseAnimation: true,
                            });
                          }
                        }}
                      >
                        {textoFicha?.botones?.leer || 'Leer'}
                      </Link>
                    }
                  </aside>

                  <section className="col-lg-8">
                    {activeTab === "informacion-basica" ?
                      <InfoBasica
                        textoFicha={textoFicha}
                        metadata={metadata}
                        idioma={idioma}
                      />
                      :
                      <InfoDetalle textoFicha={textoFicha} metadata={metadata} />
                    }

                    <div>
                      {/* blog */}
                      {linkBlog()}

                      {/* descargar, compartir y  leer */}
                      <div className={`row mx-0 justify-content-md-end`}>
                        {/* Boton de descargar libro */}
                        {(() => {
                          if (isLogged && pwaState?.clienteHabilitado && pwaState?.compatible) {
                            return (
                              /* className="ficha-botones-descarga" */
                              <div aria-live="assertive" className="ficha-botones-descarga mr-md-3 mb-3 mb-md-0">
                                {(() => {
                                  if (descargado) {
                                    return (
                                      <button className="ficha-btn-descargado ficha-btn-downloaded-book   focusable-secondary" aria-label="libro descargado" disabled>
                                        {textoFicha?.botones?.descargado || 'Descargado'}
                                      </button>
                                    );
                                  } else if (alertaLimite) {
                                    return (
                                      <button
                                        className="ficha-btn-descargar ficha-download-book focusable-secondary"
                                        disabled
                                      >
                                        {textoFicha?.botones?.descargar || 'Descargar'}
                                      </button>
                                    );
                                  } else {
                                    return (
                                      <button
                                        className="ficha-btn-descargar ficha-download-book focusable-secondary"
                                        disabled={state.descargando}
                                        onClick={() => downloadManager(metadata)}
                                      >
                                        {textoFicha?.botones?.descargar || 'Descargar'}
                                      </button>
                                    );
                                  }
                                })()}
                              </div>
                            )
                          }
                        })()}

                        {
                          isLogged &&
                          <button
                            className="ficha-btn-compartir mr-md-3 mb-3 mb-md-0 focusable-red"
                            onClick={() => {
                              MicroModal.show('modal-compartir-libro', {
                                awaitCloseAnimation: true
                              });
                            }}
                          >
                            {textoFicha?.botones?.compartir || 'Compartir'}
                            <Icon icon="ci:share" className="ml-2" width={25} />
                          </button>
                        }
                        {
                          /* (isLogged || (!isLogged && bookAvailable)) && */
                          isLogged && bookAvailable &&
                          <Link
                            to={urlLibro}
                            className="ficha-btn-enviar focusable-red"
                            onClick={(e) => {
                              if (metadata.limiteDeLecturasAlcanzado) {
                                e.preventDefault();
                                MicroModal.show("modal-alerts", {
                                  awaitCloseAnimation: true,
                                });
                              }
                            }}
                          >
                            {textoFicha?.botones?.leer || 'Leer'}
                          </Link>
                        }
                      </div>
                    </div>
                  </section>
                </div>

                {
                  !isSmallCollection && isLogged &&
                  <section>
                    <div className="ficha-subtitle">
                      <h2>{textoFicha?.interfaz?.otros_temas || 'Otros temas de tu interés'}</h2>
                      <hr />
                    </div>

                    <div className="content-temas my-4">
                      <div className="d-flex justify-content-center justify-content-lg-between flex-wrap">
                        {book.loading.recommendedTagsData ?
                          <SkeletonTagsRecomendados recommendedTags={book.recommendedTags} />
                          :
                          temasDeInteres()
                        }
                      </div>
                    </div>

                    <div className="d-flex justify-content-end my-3">
                      <Link to="/curadurias/colecciones" className="ficha-btn-ver-mas">
                        <span>{textoFicha?.botones?.recomendaciones || 'Ver más recomendaciones'}</span>
                        <Icon icon="mdi:plus-circle" className="mx-1" width={30}></Icon>
                      </Link>
                    </div>
                  </section>

                }
                {
                  !isSmallCollection && isLogged &&
                  <section>
                    <div className="ficha-subtitle">
                      <h2>{textoFicha?.interfaz?.otros_libros || 'Otros libros similares'}</h2>
                      <hr />
                    </div>
                    <div>
                      {
                        book.loading.recommendedBooksData ?
                          <SkeletonRecomendados />
                          :
                          <div className="row mx-0 justify-content-center my-4 my-lg-3">
                            {book.recommendedBooks.map((item, index) => {
                              if (item.link !== null && (index + 1) !== book.recommendedBooks.length) {
                                return (
                                  <div className="col-lg-6 px-4 mb-4 mb-lg-0" key={`recomendado-${index}`}>
                                    <Link
                                      key={index}
                                      to={item.link}
                                      className="d-sm-flex ficha-card-libro"
                                    >
                                      <div className="col-sm-4 px-0 card-portada "
                                        style={{ backgroundImage: `url(${ASSETS_URL}${item.portada})` }}
                                      ></div>
                                      <div className="col-sm-8 px-0 card-descipcion">
                                        <div className="card-title p-3">
                                          <h3>{item.titulo} </h3>
                                        </div>


                                        <div className="p-3">
                                          <p className="card-autor">{item?.autor || 'N/A'}</p>
                                          <p className="card-editorial">{item?.editorial || 'N/A'} </p>
                                          {textoMateriaTag(item.materia)}
                                          <p>{idioma === 'es' ? item?.descripcion || 'N/A' : item.descripcionEn || item?.descripcion || 'N/A'}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                );
                              }
                            })}
                          </div>
                      }
                    </div>
                  </section>
                }
              </div>

              {!isLogged &&
                <SolicitudDemo
                  imgForm={'/img/matilda-lee.png'}
                  className={'ficha-demo'}
                />
              }


            </div>


            <ModalAlertas
              id="modal-alerts"
              message={textoFicha?.mas_detalles?.modal_alerta?.mensaje || "Ya no tienes libros disponibles"}
              description={
                textoFicha?.mas_detalles?.modal_alerta?.descripcion || "Has superado el límite de libros que puedes leer en un día. Para ampliar la capacidad de tu suscripción, escríbenos a contacto@makemake.com.co`"
              }
            />
            <ModalCompartir idModal={'modal-compartir-libro'} libro={metadata} />
          </>
        }
      </>
    )
  }

}

export default Ficha;
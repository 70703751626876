import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper";

import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";

import { MAKEMAKE_PROMINENT_CLIENTS } from "./data/clients";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";

/**
 * Este componente retorna la franaja con el swiper de logos destacados. 
 * Este se usa en la tienda institucional y en el formulario de solicitud de demo
 * @returns {JSX.Element} -ProminentClients
 */
export const ProminentClients = ({staticLang, customClients}) => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
    const textos = useTranslations({ section: "tienda_makemake", lang: staticLang?staticLang:lang });
    const clients = Array.isArray(customClients)?customClients:[...MAKEMAKE_PROMINENT_CLIENTS.classics, ...MAKEMAKE_PROMINENT_CLIENTS.planLector];

    return (
        <>
            <div className="instituciones-logos d-lg-flex align-items-center px-lg-5 py-5 py-lg-4">
                <div className=" col-lg-6 col-xl-3">
                    <p className="font-weight-bold text-center text-lg-left onprimary--color">
                        {textos?.destacado?.descripcion || 'Las bibliotecas y colegios más destacados de América Latina y Estados Unidos prefieren a MakeMake'}
                    </p>
                </div>
                <div className=" col-lg-6 col-xl-9">
                    <Swiper
                        className="p-2 logo-client"
                        style={{
                            overflowX: "hidden",
                            pointerEvents: "none",
                        }}
                        slidesPerView={"auto"}
                        autoplay={{
                            delay: 0,
                            disableOnInteraction: false,
                        }}
                        speed={2 * 1000}
                        loop={true}
                        freeMode={true}
                        direction="horizontal"
                        modules={[Autoplay, FreeMode]}  
                    >
                        {clients.map((client, i) => {
                            return (
                                <SwiperSlide
                                    key={i}
                                    className="d-flex justify-content-center align-items-center mx-2 p-0"
                                    style={{
                                        minWidth: 150,
                                        maxWidth: 150,
                                        height: "auto",
                                        pointerEvents: "none",
                                    }}
                                >
                                    <img
                                        key={i}
                                        className=""
                                        style={{
                                            minWidth: 150,
                                            maxWidth: 150,
                                            height: "auto",
                                            mixBlendMode: "multiply"
                                        }}
                                        src={client?.imgUrl}
                                        alt={`client-img-${client?.imgUrl}`}
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
        </>
    )
}
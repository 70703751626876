export const MAKEMAKE_PROMINENT_CLIENTS_USA = [
	{
		imgUrl: "/img/bibliotecas/denver.jpg",
	},
	{
		imgUrl: "/img/bibliotecas/austin.png",
	},
	{
		imgUrl: "/img/bibliotecas/Berkeley.png",
	},
	{
		imgUrl: "/img/bibliotecas/Boston.png",
	},
	{
		imgUrl: "/img/bibliotecas/Charlotte.png",
	},
	{
		imgUrl: "/img/instituciones/chicago-public-schools.png",
	},
	{
		imgUrl: "/img/bibliotecas/Fresno.png",
	},
	{
		imgUrl: "/img/bibliotecas/king-county.png",
	},
	{
		imgUrl: "/img/bibliotecas/LBPL.png",
	},
	{
		imgUrl: "/img/bibliotecas/LosAngeles.png",
	},
	{
		imgUrl: "/img/instituciones/Madison.png",
	},
	{
		imgUrl: "/img/bibliotecas/Miami.png",
	},
	{
		imgUrl: "/img/instituciones/milton-school-district.png",
	},
	{
		imgUrl: "/img/bibliotecas/pflugerville.png",
	},
	{
		imgUrl: "/img/instituciones/port-chester.png",
	},
	{
		imgUrl: "/img/bibliotecas/Queens.png",
	},
	{
		imgUrl: "/img/bibliotecas/sacramento-public-library.png",
	},
	{
		imgUrl: "/img/instituciones/waukesha.png",
	},
	{
		imgUrl: "/img/bibliotecas/whittier.png",
	},
	{
		imgUrl: "/img/bibliotecas/w-j-niederkorn-library.png",
	},
];

import React from "react";
import { BGShape } from "./bg-shape";

export const ContentHeader = ({title, backgroundColor, color}) => {
	return (
		<div className="content-header titlePage" style={{backgroundColor, color}}>
			<BGShape />
			<div className="container h-100">
				<div className="row p-0 m-0 justify-content-center align-items-center h-100">
					<h1 className="col-12 title">
					    {title}
					</h1>
				</div>
			</div>
		</div>
	);
};

import React from "react";

/**
 * Este se encarga de renderizar la pantalla de 'Collection development policy'
 * @returns {JSX.Element} CollectionDevPolicy
 */
export const CollectionDevPolicy = () => {
	// const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	// const textoSoporte = useTranslations({ section: "", lang: lang});

	return (
		<>
			<div className="main-container">
				<div className="container main-content px-3 py-5 p-lg-5">
					<div className="pb-5">
						<h1 className="text-center mb-5">
							COLLECTION DEVELOPMENT POLICY - MAKEMAKE DIGITAL LIBRARY EN ESPAÑOL
						</h1>
						<p>
							Collection development policy MakeMake Digital Library en Español
							MakeMake Digital Library en Español is a reading platform designed for
							children ages 3 to 15 that offers a carefully selected collection of
							fiction and non-fiction books from more than 75 Latin American
							publishers totaling 2200 books carefully chosen for schools and
							libraries.
						</p>
            <br/>
						<p>
							About 95% of our collection is originally written in Spanish by authors
							born in Latin America, Spain and the USA. Our collection is a mix of
							fiction and non- fiction books with a strong emphasis in illustrated
							books such as comics, graphic novels, picture books and illustrated
							stories. We also strive to include diverse genres, such as Drama,
							Poetry, Novels, Short Stories, Reference and Non-Fiction. Books are
							organized according to big categories such as Genre, Topic, and Reading
							Level (First Readers approximately between 3-6 years old; Intermediate
							Readers approximately between 7-11 years old; Advanced Readers
							approximately between 12-16 years old). Categorization of books
							according to reading levels depends not only on the extension of the
							text but also on language and topic complexity. Furthermore, we have
							been categorizing all books according to reading levels (from A-Z).
						</p>
            <br/>
						<p>
							MakeMake is a multi-user access system that allows a user-friendly
							reading experience specially designed for institutions. The collection
							offers a variety of formats like flipbooks, ePubs and interactive HTML5
							books. Interactives are exclusive to MakeMake and comprise 120 books
							created in collaboration with different publishers as well as original
							creations, including Bologna Fiere winners “In my dreams” by Stephane
							Kiehl and “Doble Doble” by Menena Cottin. More than 350 books include
							guides designed to foster critical reading skills and online live events
							with authors to encourage conversations about books. Interactive books
							are the only books that do not originally exist in print, but are either
							born-digital or adaptations from print books. Interactive books try to
							foster a multi-sensory reading experience where sound, voices,
							animations and simple games encourage a different approach to books.
						</p>
            <br/>
						<p>
							As a small independent platform with an intense focus on reading
							promotion for kids in schools and libraries, MakeMake encourages access
							to the vibrating and diverse scene of independent publishers in Latin
							America. All books available in MakeMake have been published first in
							print by small to mid-size publishers from Argentina, Chile, Colombia,
							Mexico, Guatemala, Venezuela and Perú. A few Spanish publishers also
							have contributed their books to MakeMake, specially those written by
							Latin American authors. North American publishers in MakeMake are
							selected because they carry bilingual titles and also because they
							publish Central American authors but also books that represent the
							Latino experience in the USA.
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

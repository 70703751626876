import React from "react";

export const ArrowRibbon = ({color})=>{

return <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 468.76 121.49" width={'100%'} height={'100%'} preserveAspectRatio="none">
  <defs>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <polygon class="cls-1" style={{fill:color, strokeWidth:'0'}} points="468.76 0 79.88 0 37.93 0 0 0 37.93 57.69 37.93 63.8 0 121.49 79.88 121.49 79.88 121.49 468.76 121.49 468.76 0"/>
  </g>
</svg>
}